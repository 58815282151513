import Section from '../components/Section/Section';
import ShopItem from '../components/ShopItem/ShopItem';
import './StorePage.scss';

function DomikoPage() {
   return (
      <>
         <div className="shop-intro">
            <Section
               title="storeTitle"
               text="storeText"
               dark={true}
               image1="shop/shop"></Section>
         </div>
         <ShopItem
            title="veluxKlf200DriverTitle"
            text="veluxKlf200DriverText"
            dark={false}
            image="shop/veluxKlf200Driver"
            imagePadding={true}
            helpFile="Sertek.CrestronHome.VeluxKlf200.pdf"
            packageFile="Sertek.CrestronHome.VeluxKlf200.pkg"
            price={250}></ShopItem>
         <ShopItem
            title="riscoDriverTitle"
            text="riscoDriverText"
            dark={true}
            image="shop/riscoDriver"
            imagePadding={true}
            helpFile="Sertek.CrestronHome.RiscoSecurity.pdf"
            packageFile="Sertek.CrestronHome.RiscoSecurity.pkg"
            price={250}></ShopItem>
         <ShopItem
            title="netatmoEnergyDriverTitle"
            text="netatmoEnergyDriverText"
            dark={false}
            image="shop/netatmoEnergyDriver"
            imagePadding={false}
            helpFile="Sertek.CrestronHome.NetatmoEnergy.pdf"
            packageFile="Sertek.CrestronHome.NetatmoEnergy.pkg"
            price={150}></ShopItem>
         <ShopItem
            title="netatmoWeatherDriverTitle"
            text="netatmoWeatherDriverText"
            dark={true}
            image="shop/netatmoWeatherDriver"
            imagePadding={false}
            helpFile="Sertek.CrestronHome.NetatmoWeatherStation.pdf"
            packageFile="Sertek.CrestronHome.NetatmoWeatherStation.pkg"
            price={50}></ShopItem>
      </>
   );
}

export default DomikoPage;
