import { useEffect } from 'react';
import './App.scss';
import { Routes, useLocation } from 'react-router-dom';
import TopBar from './components/TopBar/TopBar';
import IntegrationPage from './pages/IntegrationPage';
import { Route } from 'react-router-dom';
import ContactPage from './pages/ContactPage';
import DomikoPage from './pages/DomikoPage';
import PrestationPage from './pages/PrestationPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import { IntlProvider } from 'react-intl';
import messageFr from './lang/fr.json';
import messageEn from './lang/en.json';
import StorePage from './pages/StorePage';
import { QueryClient } from '@tanstack/react-query';
import Medusa from '@medusajs/medusa-js';
import NetatmoTokenPage from './pages/NetatmoTokenPage';

let initLocale = 'en';
if (navigator.language === 'fr-FR' || navigator.language === 'fr') {
   initLocale = 'fr';
}

function loadMessages(locale: string) {
   switch (locale) {
      case 'fr':
         return messageFr;
      default:
         return messageEn;
   }
}

const queryClient = new QueryClient();

const medusaClient = new Medusa({
   baseUrl: 'http://sertek.fr:9000',
   maxRetries: 3,
});

function App() {
   const location = useLocation();
   console.log(navigator.language);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, [location.pathname]);

   return (
      <IntlProvider locale={initLocale} messages={loadMessages(initLocale)}>
         <TopBar />
         <div className="router">
            <Routes>
               <Route path="/" Component={IntegrationPage} />
               <Route path="/contact" Component={ContactPage} />
               <Route path="/domiko" Component={DomikoPage} />
               <Route path="/prestation" Component={PrestationPage} />
               <Route path="/store" Component={StorePage} />
               <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
               <Route path="/netatmo-token" Component={NetatmoTokenPage} />
            </Routes>
         </div>
      </IntlProvider>
   );
}

export default App;
