import Header from '../components/Header/Header';
import Partner from '../components/Partner/Partner';
import Section from '../components/Section/Section';

function IntegrationPage() {
   return (
      <>
         <Header />
         <Section
            title="integrationHomeAutomationTitle"
            text="integrationHomeAutomationText"
            image1="integration/automation"
            dark={false}
         />
         <Section
            title="integrationAudioVideoTitle"
            text="integrationAudioVideoText"
            image1="integration/audiovideo"
            dark={true}
         />
         <Section
            title="intergationAlarmTitle"
            text="integrationAlarmText"
            image1="integration/alarm"
            dark={false}
         />
         <Section
            title="integrationCameraTitle"
            text="integrationCameraText"
            image1="integration/camera"
            dark={true}
         />
         <Section
            title="integrationNetworkTitle"
            text="integrationNetworkText"
            image1="integration/wifi"
            dark={false}
         />
         <Partner></Partner>
      </>
   );
}

export default IntegrationPage;
