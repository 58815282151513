import './DomikoPage.scss';
import Section from '../components/Section/Section';
import domikoPlayStore from '../assets/img/link/domikoPlayStore.png';
import domikoAppStore from '../assets/img/link/domikoAppStore.png';
import appStoreDownload from '../assets/img/link/appStoreDownload.png';
import playStoreDownload from '../assets/img/link/playStoreDownload.png';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

let initLocale = 'en';
if (navigator.language === 'fr-FR' || navigator.language === 'fr') {
   initLocale = 'fr';
}

interface FormData {
   type: string;
   name: string;
   email: string;
   message: string;
   hp: string;
}

function DomikoPage() {
   const [formData, setFormData] = useState<FormData>({
      type: 'contact',
      name: '',
      email: '',
      message: 'domiko infos request',
      hp: '',
   });

   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const requestOptions: RequestInit = {
         method: 'POST',
         mode: 'no-cors',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify(formData),
      };

      fetch('https://sendmail-ftzz22h73q-uc.a.run.app', requestOptions);
      // .then((response) => response.json())
      // .then
      // //(data) => console.log(data)
      // ();

      // Reset the form fields
      setFormData({
         type: 'contact',
         name: '',
         email: '',
         message: '',
         hp: '',
      });
   };

   return (
      <div>
         <Section
            title="domikoPresentationTitle"
            text="domikoPresentationText"
            image1={`domiko-${initLocale}/domikoFunction`}
            image2={`domiko-${initLocale}/domikoLiving`}
            dark={true}></Section>
         <Section
            title="domikoLightingTitle"
            text="domikoLightingText"
            image1={`domiko-${initLocale}/domikoLight1`}
            image2={`domiko-${initLocale}/domikoLight2`}
            dark={false}
         />
         <Section
            title="domikoShadesTitle"
            text="domikoShadesText"
            image1={`domiko-${initLocale}/domikoObturation1`}
            image2={`domiko-${initLocale}/domikoObturation2`}
            dark={true}
         />
         <Section
            title="domikoCameraTitle"
            text="domikoCameraText"
            dark={false}
            image1={`domiko-${initLocale}/domikoCamera1`}
            image2={`domiko-${initLocale}/domikoCamera2`}
         />
         <Section
            title="domikoAlarmTitle"
            text="domikoAlarmText"
            dark={true}
            image1={`domiko-${initLocale}/domikoAlarm1`}
            image2={`domiko-${initLocale}/domikoAlarm2`}
         />
         <Section
            title="domikoHeatTitle"
            text="domikoHeatText"
            dark={false}
            image1={`domiko-${initLocale}/domikoHeat1`}
            image2={`domiko-${initLocale}/domikoHeat2`}
         />
         <Section
            title="domikoAudioTitle"
            text="domikoAudioText"
            dark={true}
            image1={`domiko-${initLocale}/domikoAudio1`}
            image2={`domiko-${initLocale}/domikoAudio2`}
         />
         <Section
            title="domikoVideoTitle"
            text="domikoVideoText"
            dark={false}
            image1={`domiko-${initLocale}/domikoVideo1`}
            image2={`domiko-${initLocale}/domikoVideo2`}
         />
         <Section
            title="domikoIntercomTitle"
            text="domikoIntercomText"
            dark={true}
            image1={`domiko-${initLocale}/domikoIntercom1`}
            image2={`domiko-${initLocale}/domikoIntercom2`}
         />
         <Section
            title="domikoChartsTitle"
            text="domikoChartsText"
            dark={false}
            image1={`domiko-${initLocale}/domikoChart1`}
            image2={`domiko-${initLocale}/domikoChart2`}
         />
         <Section
            title="domikoWateringTitle"
            text="domikoWateringText"
            dark={true}
            image1={`domiko-${initLocale}/domikoWatering1`}
            image2={`domiko-${initLocale}/domikoWatering2`}
         />
         <Section
            title="domikoBatteryTitle"
            text="domikoBatteryText"
            dark={false}
            image1={`domiko-${initLocale}/domikoSensor`}
            image2={`domiko-${initLocale}/domikoBatteries`}
         />

         <Section
            title="domikoAutomationTitle"
            text="domikoAutomationText"
            dark={true}
            image1={`domiko-${initLocale}/domikoAutomation1`}
            image2={`domiko-${initLocale}/domikoAutomation2`}
         />
         <Section
            title="domikoUserTitle"
            text="domikoUserText"
            dark={false}
            image1={`domiko-${initLocale}/domikoUser1`}
            image2={`domiko-${initLocale}/domikoUser2`}
         />
         <Section
            title="domikoSiteTitle"
            text="domikoSiteText"
            dark={true}
            image1={`domiko-${initLocale}/domikoSite1`}
            image2={`domiko-${initLocale}/domikoSite2`}
         />
         <Section
            title="domikoSetupMainTitle"
            text="domikoSetupMainText"
            dark={false}
            image1={`domiko-${initLocale}/domikoSetupMain1`}
            image2={`domiko-${initLocale}/domikoSetupMain2`}
         />
         <Section
            title="domikoSetupDriverTitle"
            text="domikoSetupDriverText"
            dark={true}
            image1={`domiko-${initLocale}/domikoSetupDriver1`}
            image2={`domiko-${initLocale}/domikoSetupDriver2`}
         />
         <Section
            title="domikoSetupVideoTitle"
            text="domikoSetupVideoText"
            dark={false}
            image1={`domiko-${initLocale}/domikoSetupVideo1`}
            image2={`domiko-${initLocale}/domikoSetupVideo2`}
         />
         <Section
            title="domikoSetupDeviceTitle"
            text="domikoSetupDeviceText"
            dark={true}
            image1={`domiko-${initLocale}/domikoSetupDevice1`}
            image2={`domiko-${initLocale}/domikoSetupDevice2`}
         />
         <div className="info-request">
            <p>
               <FormattedMessage id={'domikoInfosRequest'}></FormattedMessage>
            </p>
            <form onSubmit={handleSubmit}>
               {' '}
               <input
                  className="form__input"
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email"
                  required
                  value={formData.email}
                  onChange={handleInputChange}
               />
               <input
                  className="form__input form__hp"
                  type="text"
                  id="hp"
                  name="hp"
                  value={formData.hp}
                  onChange={handleInputChange}
               />
               <button className="button__form request-margin" type="submit">
                  Send
               </button>
            </form>
         </div>
         <div className="download">
            <div className="download-link">
               <img
                  className="qr-code"
                  src={domikoAppStore}
                  alt="QR Code pour télécharger l'application Domiko sur l'App Store d'Apple."
               />
               <img className="download-logo" src={appStoreDownload} alt="" />
            </div>
            <div className="download-link">
               <img
                  className="qr-code"
                  src={domikoPlayStore}
                  alt="Qr Code pour télécharger l'application Domiko sur le PlayStore de Google"
               />
               <img className="download-logo" src={playStoreDownload} alt="" />
            </div>
         </div>
      </div>
   );
}

export default DomikoPage;
