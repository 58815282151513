import './PrivacyPolicyPage.scss';

function PrivacyPolicy() {
   return (
      <div className="privacy">
         <h1>Domiko - Politique de confidentialité</h1>
         <p>
            Cette politique de confidentialité couvre l'utilisation de
            l'application Domiko.
         </p>
         <p>
            L'application Domiko utilise le service Google Firebase Messaging
            afin de permettre l'envoie de notifications. Le service Google
            Firebase Messaging génère des identifiants par installation qui
            n’identifient pas de manière unique un utilisateur ou un appareil
            physique. Ce service utilise l’agent utilisateur Firebase. L’agent
            utilisateur Firebase est un ensemble d'informations collectées à
            partir de la plupart des SDK Firebase et comprend les éléments
            suivants : appareil, système d'exploitation, ID de groupe
            d'applications et plate-forme de développement. L'agent utilisateur
            n'est jamais lié à un identifiant d'utilisateur ou d'appareil et est
            utilisé par l'équipe Firebase pour déterminer l'adoption de la
            plate-forme et de la version afin de mieux informer les décisions
            relatives aux fonctionnalités de Firebase. Le service de
            notification génère et collecte le jeton d’enregistrement FCM, un ID
            d’instance d’application utilisé par Firebase Messaging pour le
            notifications push. Le jeton APNs et enregistré et associé à un ID
            d’instance Firebase (jeton d’enregistrement FCM). Pour générer le
            jeton d’enregistrement FCM, les données suivantes sont collectées,
            le modèle d’appareil, la langue, le fuseau horaire, la version du
            système d’exploitation, l’identifiant de l’application et la version
            de l’application. En dehors de ce service, l'application Domiko ne
            collecte aucune donnée, statistique ou information personnelle des
            utilisateurs.
         </p>
         <p>
            L’application Domiko se connecte directement au processeur Crestron
            de l’utilisateur hébergé à son domicile. Les données affichées et
            envoyées par l’application proviennent ou sont à direction de ce
            processeur.
         </p>
         <p>
            Pour toute question, vous pouvez nous contater à
            nicolas.hallynck@gmail.com
         </p>

         <h1>Domiko - Privacy Policy</h1>
         <p>This privacy policy covers the use of Domiko app.</p>
         <p>
            The Domiko application uses the Google Firebase Messaging service
            for sending notifications. The Google Firebase Messaging service
            generate per-installation identifiers that do not uniquely identify
            a user or physical device. This service uses the Firebase user
            agent. The Firebase user agent is a bundle of information collected
            from most Firebase SDKs and includes the following: device, OS, app
            bundle ID, and developer platform. The user agent is never linked to
            a user or device identifier and is used by the Firebase team to
            determine platform and version adoption in order to better inform
            Firebase feature decisions. The notification service generates and
            collects the registration token FCM, an app instance ID used by
            Firebase Messaging to push notifications. The APNs token is
            registered and associated with an ID Firebase instance (FCM
            registration token). To generate the token FCM registration, the
            following data is collected, the model device, language, time zone,
            system version operating system, the application identifier and the
            version of the app. Apart from this service, the Domiko application
            does not collects any data, statistics or personal information from
            users.
         </p>
         <p>
            The Domiko application connects directly to the Crestron processor
            of the user hosted at his home. Data displayed and sent by the
            application come from or are directed to this processor.
         </p>
         <p>
            For any questions, you can contact us at nicolas.hallynck@gmail.com
         </p>
      </div>
   );
}

export default PrivacyPolicy;
