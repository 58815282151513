import Section from '../components/Section/Section';
import crestronCertified from '../assets/img/techno/crestronCertified.png';
import cSharp from '../assets/img/techno/cSharp.png';
import flutter from '../assets/img/techno/flutter.png';
import html5 from '../assets/img/techno/html5.png';
import knx from '../assets/img/techno/knx.png';
import rtiCorp from '../assets/img/techno/rtiCorp.jpeg';
import './PrestationPage.scss';
import { FormattedMessage } from 'react-intl';

function PrestationPage() {
   return (
      <div>
         <Section
            title="prestationTitle"
            text="prestationText"
            dark={true}
            image1="techno/service"></Section>
         <div className="techno">
            <h2>
               <FormattedMessage id="mastered_technologies"></FormattedMessage>
            </h2>
            <div className="techno__group">
               <img
                  className="techno__group__logo"
                  src={crestronCertified}
                  alt="Logo pour programmeur certifié Crestron"
               />
               <img
                  className="techno__group__logo"
                  src={cSharp}
                  alt="Logo pour le language de programmation C#"
               />
               <img
                  className="techno__group__logo"
                  src={flutter}
                  alt="Logo pour le framework flutter"
               />
               <img
                  className="techno__group__logo"
                  src={html5}
                  alt="Logo pour le language de programmation Html5"
               />
               <img
                  className="techno__group__logo techno__group__logo--small"
                  src={knx}
                  alt="Logo pour le système domotique KNX"
               />
               <img
                  className="techno__group__logo techno__group__logo--small"
                  src={rtiCorp}
                  alt="Logo pour le marque Rti"
               />
            </div>
         </div>
      </div>
   );
}

export default PrestationPage;
