import crestronLogo from '../../assets/logo/partner_crestron.jpg';
import lutronLogo from '../../assets/logo/partner_lutron.png';
import control4Logo from '../../assets/logo/partner_control4.png';
import rtiLogo from '../../assets/logo/partner_rticorp.jpeg';
import googleHomeLogo from '../../assets/logo/partner_googleHome.png';
import alexaLogo from '../../assets/logo/partner_alexa.png';
import homekitLogo from '../../assets/logo/partner_homekit.png';
import matterLogo from '../../assets/logo/partner_matter.jpeg';
import riscoLogo from '../../assets/logo/partner_risco.jpeg';
import dahuaLogo from '../../assets/logo/partner_dahua.png';
import doorbirdLogo from '../../assets/logo/partner_doorbird.png';
import mobotixLogo from '../../assets/logo/partner_mobotix.png';
import sonyLogo from '../../assets/logo/partner_sony.png';
import bangolufsenLogo from '../../assets/logo/partner_bangolufsen.png';
import devialetLogo from '../../assets/logo/partner_devialet.png';
import sonosLogo from '../../assets/logo/partner_sonos.png';
import './Partner.scss';

function Partenr() {
   return (
      <div className="partner">
         <h2>Nos marques partenaires</h2>
         <div className="partner__grid"></div>{' '}
         <div className="partner__grid">
            <img
               className="partner__logo partner__logo--ul"
               src={crestronLogo}
               alt="logo de la marque Crestron"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={lutronLogo}
               alt="logo de la marque Lutron"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={control4Logo}
               alt="logo de la marque Control4"
            />
            <img
               className="partner__logo partner__logo--ur"
               src={rtiLogo}
               alt="logo de la marque RTI Corp"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={googleHomeLogo}
               alt="logo de la marque Google Home"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={alexaLogo}
               alt="logo de la marque Amazon Alexa"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={homekitLogo}
               alt="logo de la marque Homekit"
            />
            <img
               className="partner__logo partner__logo--ur"
               src={matterLogo}
               alt="logo de la marque Matter"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={riscoLogo}
               alt="logo de la marque Risco"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={dahuaLogo}
               alt="logo de la marque Dahua"
            />
            <img
               className="partner__logo partner__logo--ul"
               src={doorbirdLogo}
               alt="logo de la marque Doorbird"
            />
            <img
               className="partner__logo partner__logo--ur"
               src={mobotixLogo}
               alt="logo de la marque Mobotix"
            />
            <img
               className="partner__logo partner__logo--bl"
               src={sonyLogo}
               alt="logo de la marque Sony"
            />
            <img
               className="partner__logo partner__logo--bl"
               src={bangolufsenLogo}
               alt="logo de la marque Bang & Olufsen"
            />
            <img
               className="partner__logo partner__logo--bl"
               src={devialetLogo}
               alt="logo de la marque Devialet"
            />
            <img
               className="partner__logo"
               src={sonosLogo}
               alt="logo de la marque Sonos"
            />
         </div>
      </div>
   );
}

export default Partenr;
