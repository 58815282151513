import './BuyDialog.scss';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

interface Props {
   open: boolean;
   driverName: string;
   closeDialog: () => void;
}

interface FormData {
   type: string;
   driverName: string;
   company: string;
   firstName: string;
   lastName: string;
   address: string;
   email: string;
   mac: string;
   hp: string;
}

function BuyDialog({ open, driverName, closeDialog }: Props) {
   const [formData, setFormData] = useState<FormData>({
      type: 'driver',
      driverName: '',
      company: '',
      firstName: '',
      lastName: '',
      address: '',
      email: '',
      mac: '',
      hp: '',
   });

   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      formData.driverName = driverName;

      // Perform any validation or data manipulation
      // ...

      const requestOptions: RequestInit = {
         method: 'POST',
         mode: 'no-cors',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify(formData),
      };

      fetch('https://sendmail-ftzz22h73q-uc.a.run.app', requestOptions)
         .then((response) => response.json())
         .then((data) => console.log(data));

      // Reset the form fields
      setFormData({
         type: 'driver',
         driverName: '',
         company: '',
         firstName: '',
         lastName: '',
         address: '',
         email: '',
         mac: '',
         hp: '',
      });

      closeDialog();
   };

   return (
      <Modal
         open={open}
         onClose={closeDialog}
         center
         classNames={{
            modal: 'customModal',
         }}>
         <div className="description">
            <h2 className="description__title">
               <FormattedMessage id="licenceOrder"></FormattedMessage>
            </h2>
            <p>
               <FormattedMessage id="orderDialog1"></FormattedMessage>
            </p>
            <p>
               <FormattedMessage id="orderDialog2"></FormattedMessage>
            </p>
         </div>
         <form className="form-order" onSubmit={handleSubmit}>
            <div className="row input-group">
               <div className="column">
                  <label className="form-order__label" htmlFor="company">
                     <FormattedMessage id="company"></FormattedMessage>
                  </label>
                  <input
                     className="form-order__input"
                     type="text"
                     id="company"
                     name="company"
                     required
                     value={formData.company}
                     onChange={handleInputChange}
                  />
                  <label className="form-order__label" htmlFor="firstname">
                     <FormattedMessage id="firstName"></FormattedMessage>
                  </label>
                  <input
                     className="form-order__input"
                     type="text"
                     id="firstName"
                     name="firstName"
                     required
                     value={formData.firstName}
                     onChange={handleInputChange}
                  />
                  <label className="form-order__label" htmlFor="lastname">
                     <FormattedMessage id="lastName"></FormattedMessage>
                  </label>
                  <input
                     className="form-order__input"
                     type="text"
                     id="lastName"
                     name="lastName"
                     required
                     value={formData.lastName}
                     onChange={handleInputChange}
                  />
               </div>
               <div className="column">
                  <label className="form-order__label" htmlFor="address">
                     <FormattedMessage id="address"></FormattedMessage>
                  </label>
                  <input
                     className="form-order__input"
                     type="text"
                     id="address"
                     name="address"
                     required
                     value={formData.address}
                     onChange={handleInputChange}
                  />
                  <label className="form-order__label" htmlFor="email">
                     <FormattedMessage id="email"></FormattedMessage>
                  </label>
                  <input
                     className="form-order__input"
                     type="email"
                     id="email"
                     name="email"
                     required
                     value={formData.email}
                     onChange={handleInputChange}
                  />
                  <label className="form-order__label" htmlFor="mac">
                     <FormattedMessage id="processorMac"></FormattedMessage>
                  </label>
                  <input
                     className="form-order__input"
                     type="text"
                     id="mac"
                     name="mac"
                     required
                     value={formData.mac}
                     onChange={handleInputChange}
                  />
                  <input
                     className="form-order__input form-order__hp"
                     type="text"
                     id="hp"
                     name="hp"
                     value={formData.hp}
                     onChange={handleInputChange}
                  />
               </div>
            </div>
            <button className="button button--send" type="submit">
               Envoyer
            </button>
         </form>
      </Modal>
   );
}

export default BuyDialog;
