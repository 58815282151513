import { useEffect } from 'react';

function NetatmoTokenPage() {
   useEffect(() => {
      // Accéder à l'URL après le hash
      const queryString = window.location.hash.split('?')[1];

      // Utiliser URLSearchParams pour obtenir les paramètres
      const params = new URLSearchParams(queryString);

      const state = params.get('state'); // 'unique_state'
      const code = params.get('code'); // '07b0b6790f17602447e2073895f35d07'

      console.log('State:', state);
      console.log('Code:', code);

      if (code !== null) {
         // Utiliser le code pour obtenir le token
         fetch('https://netatmogettoken-ftzz22h73q-uc.a.run.app', {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: code, scope: 'read_station' }),
         })
            .then((response) => response.json())
            .then((data) => {
               console.log('Token:', data);
            })
            .catch((error) => {
               console.error('Error:', error);
            });
      }
   }, []);

   return (
      <div>
         <p>Netatmo Token</p>
      </div>
   );
}

export default NetatmoTokenPage;
