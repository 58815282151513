import './IllustrationSolo.scss';

interface Props {
   image: string;
}

function IllustationSolo({ image }: Props) {
   const imagePath = require(`../../assets/img/${image}.webp`);

   return <img className="image" src={imagePath} alt="" />;
}

export default IllustationSolo;
