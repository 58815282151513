import './ContactPage.scss';
import linkedIn from '../assets/img/contact/linkedIn.png';
import instagram from '../assets/img/contact/instagram.webp';
import { useState } from 'react';

interface FormData {
   type: string;
   name: string;
   email: string;
   message: string;
   hp: string;
}

function ContactPage() {
   const [formData, setFormData] = useState<FormData>({
      type: 'contact',
      name: '',
      email: '',
      message: '',
      hp: '',
   });

   const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleTextAreaChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
   ) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
   };

   const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const requestOptions: RequestInit = {
         method: 'POST',
         mode: 'no-cors',
         headers: { 'Content-Type': 'application/json' },
         body: JSON.stringify(formData),
      };

      fetch('https://sendmail-ftzz22h73q-uc.a.run.app', requestOptions);
      // .then((response) => response.json())
      // .then
      // //(data) => console.log(data)
      // ();

      // Reset the form fields
      setFormData({
         type: 'contact',
         name: '',
         email: '',
         message: '',
         hp: '',
      });
   };

   return (
      <div className="contact">
         <div className="contact__group">
            <div className="contact__details">
               <div>
                  <p>SERTEK</p>
                  <p>Nicolas Hallynck</p>
                  <p>88 Avenue Magellan</p>
                  <p>94000 Créteil</p>
                  <p>+33 6 86 63 42 98</p>
               </div>
               <div className="contact__details__link">
                  <a
                     href="https://www.linkedin.com/in/nicolas-hallynck-11348b164/"
                     target="_blank"
                     rel="noreferrer">
                     <img
                        className="contact__details__link__img"
                        src={linkedIn}
                        alt=""
                     />
                  </a>
                  <a
                     href="https://www.instagram.com/sertek_automation/"
                     target="_blank"
                     rel="noreferrer">
                     <img
                        className="contact__details__link__img"
                        src={instagram}
                        alt=""
                     />
                  </a>
               </div>
            </div>
            <div className="contact__form">
               <form onSubmit={handleSubmit}>
                  <div className="form__group">
                     <label className="form__label" htmlFor="name">
                        Name
                     </label>
                     <input
                        className="form__input"
                        type="text"
                        id="name"
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                     />
                  </div>
                  <div className="form__group">
                     <label className="form__label" htmlFor="email">
                        Email
                     </label>
                     <input
                        className="form__input"
                        type="email"
                        id="email"
                        name="email"
                        required
                        value={formData.email}
                        onChange={handleInputChange}
                     />
                  </div>
                  <div className="form__group">
                     <label className="form__label" htmlFor="message">
                        Message
                     </label>
                     <textarea
                        className="form__input"
                        id="message"
                        name="message"
                        required
                        value={formData.message}
                        onChange={handleTextAreaChange}></textarea>
                  </div>
                  <div className="form__groups">
                     <input
                        className="form__input form__hp"
                        type="text"
                        id="hp"
                        name="hp"
                        value={formData.hp}
                        onChange={handleInputChange}
                     />
                  </div>
                  <button className="button__form" type="submit">
                     Submit
                  </button>
               </form>
            </div>
         </div>
      </div>
   );
}

export default ContactPage;
