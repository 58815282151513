import './IllustrationDuo.scss';

interface Props {
   image1: string;
   image2: string;
}

function IllustrationDuo({ image1, image2 }: Props) {
   const image1Path = require(`../../assets/img/${image1}.webp`);
   const image2Path = require(`../../assets/img/${image2}.webp`);

   return (
      <div className="images-group">
         <img className="image image__1" src={image1Path} alt="" />
         <img className="image image__2" src={image2Path} alt="" />
      </div>
   );
}

export default IllustrationDuo;
