import './Header.scss';
import background from '../../assets/img/integration/header.webp';
import { FormattedMessage } from 'react-intl';

function Header() {
   return (
      <div className="header">
         <img src={background} alt="" className="header__background" />
         <div className="blur-section">
            <h1 className="title title--down">
               <FormattedMessage id="headerTitle"></FormattedMessage>
            </h1>
            <p className="blur-section__text">
               <FormattedMessage id="headerText"></FormattedMessage>
            </p>
         </div>
      </div>
   );
}

export default Header;
